<template>
  <section>
    <content-header :title="$t('otherMembersMaintenance')" />
    <div class="container-fluid">
      <div class="main-page-container">
        <div class="row">
          <sub-header :title="$t('filtersMembers')" />
        </div>
        <filter-manager
          ref="filterComponent"
          v-model="selectedFilters"
          class="mb-4"
          :display-advanced="false"
          :filters="filtersConfig"
          :search-text="$t('filter')"
          @search="getMembers(1)"
        />
      </div>

      <separator class="my-4" :text="$t('listOfMembers')" />
      <div class="row mt-4 mb-3">
        <div class="col-sm-12">
          <base-filled-button
            class="float-right"
            icon-class="fas fa-plus"
            :text="$t('create')"
            :on-click="() => (showCreateModal = true)"
          />
        </div>
      </div>
      <CustomNotFound v-if="noResultsFound" :text="$t('noResultsFound')" />
      <div class="row">
        <div
          v-for="(member, index) in members.data"
          :key="index"
          class="mt-3 col-12"
        >
          <search-member-result
            :value="member"
            :index="index"
            :index-in-edit-mode="indexInEditMode"
            @edit="indexInEditMode = $event"
            @on-delete="openDeleteModal(member)"
          />
        </div>
      </div>
      <custom-pagination
        v-if="members.count > 0"
        id="pgPager"
        class="my-4"
        :data="members"
        :page-size="pageSize"
        @pagination-go-page="getMembers($event)"
        @pagination-rows-per-page="getMembers(pageIndex, $event)"
      />
    </div>
    <custom-modal
      v-model="showCreateModal"
      size="md"
      :title="$t('otherMembersMaintenance')"
    >
      <CreateMember
        :company-id="companyId"
        @close="showCreateModal = false"
        @submitted="getMembers()"
      />
    </custom-modal>
    <DeleteModal
      v-model="showDeleteModal"
      error-message="emailIsNotCorrect"
      :item="member"
      label="email"
      name="email"
      @on-confirm="onDelete()"
    />
  </section>
</template>

<script>
import CustomPagination from '@/components/CustomPagination';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import SearchMemberResult from './components/SearchMemberResult';
import FilterManager from '@/components/FilterManager';
import contentHeader from '@/components/Header';
import SubHeader from '@/components/SubHeader';
import Separator from '@/components/Separator';
import {
  getOtherMembers as _getOtherMembers,
  deleteMember as _deleteMember,
} from '@/services/CompanyService';
import CustomModal from '@/components/basics/modal/CustomModal';
import CreateMember from './components/CreateMember';
import DeleteModal from '@/components/DeleteModal';

export default {
  name: 'OtherMembers',
  components: {
    CustomPagination,
    CustomNotFound,
    SearchMemberResult,
    FilterManager,
    contentHeader,
    SubHeader,
    Separator,
    CustomModal,
    CreateMember,
    DeleteModal,
  },
  props: {
    companyId: {
      type: Number,
    },
  },
  data() {
    return {
      selectedFilters: {
        email: '',
        name: '',
      },
      members: {
        data: [],
        totalItemCount: 0,
        count: 0,
        pageCount: 0,
        currentPage: 1,
      },
      pageSize: 10,
      pageIndex: 1,
      noResultsFound: false,
      showCreateModal: false,
      showDeleteModal: false,
      member: null,
      indexInEditMode: null,
    };
  },
  mounted() {
    this.getMembers(1);
  },
  computed: {
    filtersConfig() {
      return [
        {
          rules: '',
          name: 'email',
          label: this.$t('email'),
          component: 'BaseInput',
          placeholder: this.$t('email'),
          class: 'col-sm-12 col-md-4',
          initialValue: this.selectedFilters.email,
          value: this.selectedFilters.email,
        },
        {
          rules: '',
          name: 'name',
          label: this.$t('name'),
          component: 'BaseInput',
          placeholder: this.$t('name'),
          class: 'col-sm-12 col-md-4',
          initialValue: this.selectedFilters.name,
          value: this.selectedFilters.name,
        },
      ];
    },
  },

  methods: {
    async getMembers(pageIndex = null, pageSize = null) {
      this.pageSize = pageSize || this.pageSize;
      this.pageIndex = pageIndex || this.pageIndex;

      const payload = {
        CompanyId: this.companyId,
        Name: this.selectedFilters.name || null,
        Email: this.selectedFilters.email || null,
        PageIndex: this.pageIndex,
        PageSize: this.pageSize,
      };

      await _getOtherMembers(payload)
        .then(({ data }) => {
          this.members = data;
          this.noResultsFound = !this.members.count;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    openDeleteModal(member) {
      this.member = member;
      this.showDeleteModal = true;
    },
    onDelete() {
      _deleteMember(this.member.id)
        .then(async () => {
          this.ShowSuccessToast(this.$t('successfullyDeleted'));
          this.showDeleteModal = false;
          await this.getMembers();
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>
