<template>
  <div class="row">
    <div v-if="step === 1" class="col-md-12">
      <div class="row">
        <div class="col-md-12">
          <MultiTransfer
            :options="locations"
            :value="selectedLocations"
            :title-availables="$t('availableLocations')"
            :title-selected="$t('selectedLocations')"
            @onAdded="onLocationsAdded"
            @onRemoved="onLocationssRemoved"
          />
        </div>
        <div class="col-md-12 text-right">
          <button
            class="btn btn-outline-primary"
            :disabled="!selectedLocations.length"
            @click="nextStep()"
          >
            {{ $t('selectDate') }} <i class="far fa-arrow-alt-circle-right" />
          </button>
        </div>
      </div>
    </div>
    <div v-if="step === 2" class="col-md-12">
      <div class="row">
        <div class="col-md-12" style="min-height: 300px">
          <base-datetime-picker
            class="theme-orange"
            type="date"
            @change="onAddNonWorkingDate($event)"
          />

          <label
            v-for="(workingDate, index) in nonWorkingDates"
            :key="index"
            type="button"
            class="btn btn-primary mr-2"
            style="border-radius: 20px"
          >
            {{ workingDate | date }}
            <i
              class="fa fa-times-circle ml-5"
              @click="removeNonWorkingDate(index)"
            ></i>
          </label>
        </div>
        <div class="col-md-12 text-right">
          <button class="btn btn-secondary float-left" @click="step = 1">
            <i class="far fa-arrow-alt-circle-left" /> {{ $t('return') }}
          </button>
          <button
            class="btn btn-primary"
            :disabled="!nonWorkingDates.length"
            @click="onSubmit"
          >
            <i class="fal fa-save" /> {{ $t('save') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getLocationList as _getLocationList } from '@/services/LocationService';
import { addLocationNonWorkingDays as _addLocationNonWorkingDays } from '@/services/CompanyService';
import MultiTransfer from '@/components/MultiTransfer';

export default {
  name: 'AddNonWorkingDays',
  components: {
    MultiTransfer,
  },
  props: {
    companyId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      locations: [],
      selectedLocations: [],
      step: 1,
      nonWorkingDates: [],
    };
  },
  mounted() {
    _getLocationList(this.companyId).then((response) => {
      this.locations = response.data;
    });
  },
  methods: {
    onLocationsAdded(toAdd) {
      this.selectedLocations.push(...toAdd);
    },
    onLocationssRemoved(toRemove) {
      toRemove.forEach((e) => {
        let index = this.selectedLocations.indexOf(e);
        this.selectedLocations.splice(index, 1);
      });
    },
    nextStep() {
      this.nonWorkingDates = [];
      this.step = 2;
    },
    onAddNonWorkingDate(date) {
      if (!date) return;
      if (this.nonWorkingDates.find((x) => x === date)) return;
      this.nonWorkingDates.push(date);
    },
    removeNonWorkingDate(index) {
      this.nonWorkingDates.splice(index, 1);
    },
    onSubmit() {
      const payload = [];
      this.selectedLocations.forEach((location) => {
        this.nonWorkingDates.forEach((nonWorkingDate) => {
          payload.push({
            locationConfigurationId: location.id,
            date: nonWorkingDate,
          });
        });
      });
      _addLocationNonWorkingDays(this.companyId, payload)
        .then(() => {
          this.ShowSuccessSaveToast();
          this.$emit('close');
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>
