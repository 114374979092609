<template>
  <div class="row">
    <div class="col-md-7">
      <filter-select
        v-model="selectedLocations"
        :clearable="false"
        fieldtext="name"
        fieldvalue="id"
        :label="$t('locations')"
        :options="collections.locations"
        :is-multiple="true"
      />
      <div class="row px-3">
        <div class="col-md-12 px-0">
          <hr />
        </div>
        <div
          v-for="(date, i) in nonWorkingDays"
          :key="i"
          class="col-md-12 date-box py-3 text-center mb-2"
          :class="{ active: selectedDate === date }"
          @click="onSelect(date)"
        >
          {{ date | date }} <i class="fal fa-chevron-circle-right"></i>
        </div>
      </div>
    </div>
    <div class="col-md-5 mt-5">
      <label
        v-for="(location, index) in locations"
        :key="index"
        class="btn btn-primary mr-2"
        style="border-radius: 20px; width: 100%"
      >
        {{ location.locationName }}
        <i class="fa fa-times-circle float-right mt-1" @click="onRemove(location.id)"></i>
      </label>
    </div>
  </div>
</template>

<script>
import { getLocationList as _getLocationList } from '@/services/LocationService';
import {
  getLocationNonWorkingDays as _getLocationNonWorkingDays,
  removeNonWorkingDay as _removeNonWorkingDay,
  getNonWorkingDaysOffices as _getNonWorkingDaysOffices,
} from '@/services/CompanyService';

export default {
  name: 'RemoveNonWorkingDays',
  props: {
    companyId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      selectedLocations: [],
      collections: {
        locations: [],
      },
      locations: [],
      nonWorkingDays: [],
      selectedDate: null,
    };
  },
  async mounted() {
    await _getLocationList(this.companyId).then((response) => {
      this.collections.locations = response.data;
    });
    this.getNonWorkingDates();
  },
  watch: {
    selectedLocations() {
      this.getNonWorkingDates();
    },
  },
  methods: {
    getNonWorkingDates() {
      this.selectedDate = null;
      this.locations = [];
      const payload = this.selectedLocations.length
        ? this.selectedLocations
        : this.collections.locations.map((x) => x.id);
      _getLocationNonWorkingDays(this.companyId, payload).then((response) => {
        this.nonWorkingDays = response.data;
      });
    },
    loadNonWorkingDayLocations(date) {
      _getNonWorkingDaysOffices(this.companyId, date).then((response) => {
        this.locations = response.data;
      });
    },
    onRemove(id) {
      _removeNonWorkingDay(this.companyId, id)
        .then(() => {
          this.ShowSuccessToast(this.$t('successfullyDeleted'));
          this.loadNonWorkingDayLocations(this.selectedDate);
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onSelect(date) {
      this.locations = [];
      this.selectedDate = this.selectedDate !== date ? date : null;
      if (!this.selectedDate) return;

      this.loadNonWorkingDayLocations(this.selectedDate);
    },
  },
};
</script>
<style lang="scss" scoped>
.date-box {
  border: 1px solid #ff8105;
  border-radius: 10px;
  color: #ff8105;
  font-weight: bold;
  cursor: pointer;
  i {
    font-size: 20px;
  }
  &:hover {
    background: #ff8105;
    color: white;
  }
  &.active {
    background: #ff8105;
    color: white;
  }
}
</style>
