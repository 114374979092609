import { apiTurnos } from '@/core/api/';

export const getAuthClientsList = () => 
  apiTurnos.get(`/api/authclient/list`);

export const getAuthClients = (payload) => 
  apiTurnos.get(`/api/authclient/search`, { params: { ...payload } });

export const createAuthClient = (payload) => 
  apiTurnos.post(`/api/authclient`, payload);

export const editAuthClient = (payload) => 
  apiTurnos.put(`/api/authclient`, payload);