<template>
  <div class="row">
    <div class="col-md-5">
      <div class="items-selection availables-items">
        <div class="text-left mb-3">
          <strong>{{ titleAvailables }}</strong>
        </div>
        <div class="form-group selectable-items mb-3">
          <select
            id="availables-items"
            v-model="leftOptionsSelectedIds"
            class="form-control p-3"
            multiple
            name="availabes-items"
          >
            <option v-for="item in fullOptions" :key="item.id" :value="item.id">
              {{ item.name }}
            </option>
          </select>
        </div>
        <div class="options d-flex justify-content-between">
          <span class="select-all" @click="selectAllLeftOptions">
            {{ $t('selectall') }}
          </span>
          <span class="available-count">
            {{ $t('available') }} ({{
              fullOptions != undefined ? fullOptions.length : 0
            }})
          </span>
        </div>
      </div>
    </div>
    <div class="col-md-2 selection-actions-container">
      <div class="selection-actions align-self-center">
        <button
          class="add-items btn-block btn btn-sm btn-primary mb-3 pr-sm-0 pl-sm-0"
          type="button"
          @click="onAdd"
        >
          {{ $t('add') }} <span class="fal fa-chevron-right" />
        </button>
        <button
          class="remove-items btn-block btn btn-sm btn-secondary pr-sm-0 pl-sm-0"
          type="button"
          @click="onRemove"
        >
          <span class="fal fa-chevron-left" /> {{ $t('remove') }}
        </button>
      </div>
    </div>
    <div class="col-md-5">
      <div class="items-selection selected-items">
        <div class="text-left mb-3">
          <strong>{{ titleSelected }}</strong>
        </div>
        <div class="form-group mb-3 selectable-items">
          <select
            id="selected-items"
            v-model="rightOptionsSelectedIds"
            class="form-control p-3"
            multiple
            name="selected-items"
          >
            <option v-for="item in value" :key="item.id" :value="item.id">
              {{ item.name }}
            </option>
          </select>
        </div>
        <div class="options d-flex justify-content-between">
          <span class="select-all" @click="selectAllRightOptions">
            {{ $t('selectall') }}
          </span>
          <span class="selected-count">
            {{ $t('selected') }} ({{ value != undefined ? value.length : 0 }})
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MultiTransfer',
  props: {
    titleAvailables: {
      type: String,
      default: '',
    },
    titleSelected: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    fullOptions() {
      return this.options.filter((e) => {
        return !this.value.some((el) => {
          return el.id === e.id;
        });
      });
    },
  },
  data() {
    return {
      leftOptionsSelectedIds: [],
      rightOptionsSelectedIds: this.value,
    };
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal != oldVal) {
        this.rightOptionsSelectedIds = newVal;
      }
    },
  },
  methods: {
    selectAllLeftOptions() {
      this.leftOptionsSelectedIds = this.fullOptions.map((e) => e.id);
    },
    selectAllRightOptions() {
      let toBeSelected = [];
      this.value.forEach((e) => {
        toBeSelected.push(e.id);
      });
      this.rightOptionsSelectedIds = toBeSelected;
    },
    onAdd(event) {
      event.preventDefault();
      let toAdd = [];
      this.options.forEach((e) => {
        if (this.leftOptionsSelectedIds.includes(e.id)) {
          toAdd.push(e);
        }
      });
      this.$emit('onAdded', toAdd);
      this.leftOptionsSelectedIds = [];
    },
    onRemove(event) {
      event.preventDefault();

      let toRemove = [];
      this.options.forEach((e) => {
        if (this.rightOptionsSelectedIds.includes(e.id)) {
          toRemove.push(e);
        }
      });
      this.$emit('onRemoved', toRemove);
      this.rightOptionsSelectedIds = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.collapse-container {
  background: $color-app-background;
  border-radius: 10px;
}
.acordion-title {
  text-align: left;
  opacity: 1;
  font: normal normal bold 16px/19px Lato;
  letter-spacing: 0px;
  color: $color-primary;
  padding: 18px 10px 18px 18px;
}
.items-selection {
  margin-bottom: 34px;
}
.items-selection select {
  height: 217px !important;
}
.items-selection strong {
  font: normal normal bold 16px/21px Lato !important;
  letter-spacing: 0px;
  color: $color-font-primary;
  opacity: 1;
}
.select-all,
.available-count,
.selected-count,
.selectable-items {
  font: normal normal normal 14px/19px Lato !important;
  color: $color-font-primary !important;
}

.selectable-items {
  select {
    padding-left: 0px !important;
    padding-right: 0px !important;

    option {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
      font: normal normal normal 14px/19px Lato !important;
      color: $color-font-primary !important;
    }
  }
}

.selection-actions {
  font: normal normal bold 14px/19px Lato;
  color: $color-white;
  width: 100%;
}
.select-all {
  text-decoration: underline;
  cursor: pointer;
}

#availables-items {
  padding: 0px !important;
  option {
    padding: 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    border-top: solid $color-label-default 1px;
    white-space: normal;
  }
}

select:-internal-list-box option:checked {
  background-color: $color-primary !important;
}

.selection-actions-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-divider {
  width: 98%;
  border-top: 1px solid #e0e0e0;
  /* text-align: center; */
  margin-left: auto;
  margin-right: auto;
}

.selectable-items {
  select option:hover,
  select option:focus,
  select option:active,
  select option:checked,
  select option:focus-visible,
  select option:focus-within {
    background: linear-gradient($color-primary, $color-primary);
    background-color: $color-primary !important; /* for IE */
    font: normal normal normal 14px/19px Lato !important;
    color: $color-font-primary !important;
  }
}

select#selected-items option {
  padding: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  border-top: solid $color-label-default 1px;
  white-space: normal;
}

#selected-items {
  padding: 0px !important;
}
</style>
