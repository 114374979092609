<template>
  <b-card border-variant="light" class="py-0 listview">
    <b-row>
      <b-col class="col-12 col-sm-12 col-md-4 col-lg-4">
         <div class="cell">
          <label>{{ $t('userName') }}: </label>
          <span class="red">{{ value.userName }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('roles') }}: </label>
          <span>{{ parseRoleNames(value.roleNames) || '-' }}</span>
        </div>

        <div class="cell">
          <label>{{ $t('email') }}: </label>
          <span>{{ value.email || '-' }}</span>
        </div>
      </b-col>
      <b-col class="col-12 col-sm-12 col-md-4 col-lg-3">
        <div class="cell">
          <label>{{ $t('name') }}: </label>
          <span>{{ value.firstName || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('paternalLastName') }}: </label>
          <span>{{ value.paternalLastName || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('maternalLastName') }}: </label>
          <span>{{ value.maternalLastName || '-' }}</span>
        </div>
      </b-col>



      <b-col class="col-12 col-sm-12 col-md-4 col-lg-3">
        <div class="cell">
          <label>{{ $t('mobile') }}: </label>
          <span>{{ value.phoneNumber | telephone }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('provider') }}: </label>
          <span>{{ value.phoneProviderName || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('active') }}: </label>
          <span>{{ $t(value.isActive ? 'yes' : 'no') }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('blocked') }}: </label>
          <span>{{ $t(value.isBlocked ? 'yes' : 'no') }}</span>
        </div>
      </b-col>
      <b-col class="col-12 col-sm-12 col-md-12 col-lg-2">
        <action-button :actions="actions" :text="$t('actions')" />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import ActionButton from '@/components/basics/buttons/ActionButton';

export default {
  name: 'UserSearchResult',
  components: {
    ActionButton,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    actions() {
      return [
        {
          label: this.$t('assignOffice'),
          iconClass: 'far fa-plus-square',
          iconColor: '#24852E',
          action: () => this.$emit('assign'),
          visible: () => !!this.value.companyId,
        },
        {
          label: this.$t(this.value.isBlocked ? 'unlock' : 'block'),
          iconClass: this.value.isBlocked ? 'far fa-user-unlock' : 'far fa-user-lock',
          iconColor: this.value.isBlocked ? '#FF8105' : '#FF0000',
          action: () => this.$emit('block'),
          visible: () => this.value.isActive,
        },
      ];
    }
  },
  methods: {
    parseRoleNames(roleNames) {
      if (!roleNames) return '-';

      const rolesList = roleNames.toLowerCase().replace(' ', '').split(',');
      const result = [];

      rolesList.forEach((role) => {
        result.push(this.$t('role' + role));
      });

      return result.join(', ');
    },
  },
};
</script>

<style lang="scss" scoped>
.listview .cell {
  clear: left;
}

.listview .cell label {
  float: left;
  font-weight: bold;
}

.listview .cell span,
::v-deep .formatted-text label {
  margin-left: 5px;
  color: $color-primary;
  font-weight: bold;
}
</style>
