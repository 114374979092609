<template>
  <div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <filter-select
          v-model="selectedAuthClient1"
          fieldtext="name"
          fieldvalue="id"
          :is-multiple="false"
          :clearable="true"
          label="Turnos Legacy"
          :options="availableAuthClients || []"
          :placeholder="$t('select')"
          :return-object="true"
          @change="onAuthClient1Changed($event)"
        />
      </div>
      <div class="col-sm-12 col-md-6">
        <filter-select
          v-model="selectedAuthClient2"
          fieldtext="name"
          fieldvalue="id"
          :is-multiple="false"
          :clearable="true"
          label="Turnos R8"
          :options="availableAuthClients || []"
          :placeholder="$t('select')"
          :return-object="true"
          @change="onAuthClient2Changed($event)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <button
          class="btn btn-primary float-right"
          variant="primary"
          @click="onSubmit()"
        >
          <i class="far fa-save" /> {{ $t('save') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import {
  editAdClients as _editAdClients 
} from '@/services/CompanyService';

import { 
  getAuthClientsList as _getAuthClientsList
} from '@/services/AuthClientService';

export default {
  name: 'SSOForm',
  components: { },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedAuthClient1: null,
      selectedAuthClient2: null,
      availableAuthClients: []
    };
  },
  async mounted() {
    await _getAuthClientsList().then((resp) => {
      this.availableAuthClients = resp.data || [];

      if(this.value.adClientId) {
        const ac1 = this.availableAuthClients.filter(x => x.id == this.value.adClientId);

        this.selectedAuthClient1 = ac1.length ? ac1[0] : null;
      }

      if(this.value.adClientId2){
        const ac2 = this.availableAuthClients.filter(x => x.id == this.value.adClientId2);

        this.selectedAuthClient2 = ac2.length ? ac2[0] : null;
      }
    })
    .catch((error) => this.ShowErrorToast(error.response.data.message));
  },
  methods: {
    async onAuthClient1Changed(value) {
      this.selectedAuthClient1 = value;
    },
    async onAuthClient2Changed(value) {
      this.selectedAuthClient2 = value;
    },
    async onSubmit() {
      const payload = {
        authClientId1: this.selectedAuthClient1 ? this.selectedAuthClient1.id : null,
        authClientId2: this.selectedAuthClient2 ? this.selectedAuthClient2.id : null
      };

      await _editAdClients(this.value.id, payload)
      .then(() => {
        this.value.adClientId = payload.authClientId1;
        this.value.adClientId2 = payload.authClientId2;

        this.ShowSuccessSaveToast();
        this.$emit('submitted');
        this.$emit('close');
      })
      .catch((error) => this.ShowErrorToast(error.response.data.message));
    }
  },
};
</script>
<style lang="scss" scoped>
</style>
