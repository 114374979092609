<template>
  <div class="p-4 listview card border-light">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-3">
        <div class="cell">
          <label>{{ $t('name') }}</label>
          <span>{{ value.name || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('disclaimer') }}</label>
          <span>{{ value.disclaimerWith || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('visitFactor') }}</label>
          <span>{{ value.visitFactor || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('workingDaysPerWeek') }}</label>
          <span>{{ value.workingDays || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('workingHoursPerDay') }}</label>
          <span>{{ value.workingHours || '-' }}</span>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-3">
        <div class="cell">
          <label>{{ $t('waitingTimeInHours') }}</label>
          <span>{{ value.waitingTimeHours || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('finalPaymentDate') }}</label>
          <span>{{ value.paymentEndDate | date }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('alertDaysBeforeSuspension') }}</label>
          <span>{{ value.alertDaysBeforeSuspension || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('alertDaysAfterSuspension') }}</label>
          <span>{{ value.alertDaysAfterSuspension || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('available') }}</label>
          <span>{{ $t(value.enabled ? 'yes' : 'no') }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('showAppointmentsPlusToPublic') }}</label>
          <span>{{ $t(value.allowEntryByInitialPage ? 'yes' : 'no') }}</span>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-3">
        <div class="cell">
          <label>{{ $t('miscellaneous') }} 1</label>
          <span>{{ $t(value.misc1 ? 'yes' : 'no') }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('nameMisc') }} 1</label>
          <span>{{ value.misc1Name || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('coordinatorFilter') }}</label>
          <span>{{ $t(value.filterBySpecialist ? 'yes' : 'no') }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('belongsToGovernment') }}</label>
          <span>{{ $t(value.belongsToGovernment ? 'yes' : 'no') }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('videoCallNotificationOnCreateTurn') }}</label>
          <span>{{
            $t(value.videoCallNotificationOnCreateTurn ? 'yes' : 'no')
          }}</span>
        </div>
        <div class="cell">
          <label>{{
            $t('disableEmailRequiredForAppointmentCoordination')
          }}</label>
          <span>{{
            $t(
              value.disableEmailRequiredForAppointmentCoordination
                ? 'yes'
                : 'no',
            )
          }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('allowCurrentDayCoordination') }}</label>
          <span>{{ $t(value.allowCurrentDayCoordination ? 'yes' : 'no') }}</span>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-3">
        <action-button :actions="actions" :text="$t('actions')" />
      </div>
    </div>
  </div>
</template>

<script>
import ActionButton from '@/components/basics/buttons/ActionButton';

export default {
  name: 'CompanySearchResult',
  components: {
    ActionButton,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      actions: [
        {
          label: this.$t('edit'),
          iconClass: 'far fa-edit',
          iconColor: '#105AEE',
          action: () =>
            this.$router.push({
              name: 'CompanyEdit',
              params: { id: this.value.id },
            }),
        },
        {
          label: this.$t('singlesignon'),
          iconClass: 'far fa-sign-in',
          iconColor: '#105AEE',
          action: () => this.$emit('on-sso'),
        },
        {
          label: this.$t('notifications'),
          iconClass: 'far fa-sms',
          iconColor: '#198E0F',
          action: () => this.$emit('on-notification'),
        },
        {
          label: this.$t('addMembers'),
          iconClass: 'far fa-plus',
          iconColor: '#198E0F',
          action: () =>
            this.$router.push({
              name: 'CompanyAddMembers',
              params: { id: this.value.id },
            }),
        },
        {
          label: this.$t('addNonBusinessDays'),
          iconClass: 'far fa-plus-square',
          iconColor: '#198E0F',
          action: () => this.$emit('add-non-business-days'),
        },
        {
          label: this.$t('removeNonBusinessDays'),
          iconClass: 'far fa-minus-square',
          iconColor: '#FF0000',
          action: () => this.$emit('remove-non-business-days'),
        },
        {
          label: this.$t('syncChatUsers'),
          iconClass: 'fab fa-rocketchat',
          iconColor: '#F5455C',
          action: () => this.$emit('on-sync-chat-users'),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.listview .cell {
  clear: left;
}
.listview .cell label {
  float: left;
  font-weight: bold;
}
.listview .cell span,
::v-deep .formatted-text label {
  margin-left: 5px;
  color: $color-primary;
  font-weight: bold;
}
.card {
  word-break: break-word;
}
</style>
