<template>
  <validation-observer ref="formRef" novalidate>
    <div class="row">
      <div class="col-md-12">
        <validation-provider v-slot="{ errors }" rules="required|email|max:128">
          <base-input
            v-model="model.email"
            :error="errors[0]"
            :error-msg="$t('invalidField')"
            :label="$t('email')"
          />
        </validation-provider>
      </div>
      <div class="col-md-12">
        <validation-provider v-slot="{ errors }" rules="required|max:128">
          <base-input
            v-model="model.name"
            :error="errors[0]"
            :error-msg="$t('invalidField')"
            :label="$t('name')"
          />
        </validation-provider>
      </div>
      <div class="col-md-12">
        <validation-provider v-slot="{ errors }" rules="required|max:128">
          <base-input
            v-model="model.lastName"
            :error="errors[0]"
            :error-msg="$t('invalidField')"
            :label="$t('paternalLastName')"
          />
        </validation-provider>
      </div>
      <div class="col-md-12">
        <validation-provider v-slot="{ errors }" rules="max:128">
          <base-input
            v-model="model.secondLastName"
            :error="errors[0]"
            :error-msg="$t('invalidField')"
            :label="$t('maternalLastName')"
          />
        </validation-provider>
      </div>
      <div class="col-md-12 text-right mt-3">
        <base-filled-button
          bg-color="#707070"
          class="mx-0 float-left"
          icon-class="fas fa-ban"
          :on-click="() => $emit('close')"
          :text="$t('cancel')"
        />

        <button
          class="btn btn-outline-primary"
          variant="outline-primary"
          @click="onSubmit()"
        >
          <i class="far fa-save" /> {{ $t('save') }}
        </button>
      </div>
    </div>
  </validation-observer>
</template>
<script>
import BaseInput from '@/components/BaseInput';
import { createMember as _createMember } from '@/services/CompanyService';

export default {
  name: 'MemberCreate',
  components: {
    BaseInput,
  },
  props: {
    companyId: {
      type: Number,
    },
  },
  data() {
    return {
      model: {
        email: null,
        name: null,
        lastName: null,
        secondLastName: null,
      },
    };
  },
  methods: {
    async onSubmit() {
       if (!(await this.$refs.formRef.validate())) return;
      this.model.companyId = this.companyId;
      _createMember(this.model)
        .then(() => {
          this.ShowSuccessSaveToast();
          this.$emit('close');
          this.$emit('submitted');
        })
        .catch((error) => {
          this.ShowErrorToast(error.response.data.message);
        });
    },
  },
};
</script>
