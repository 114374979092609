<template>
  <div class="row">
    <div class="col-md-12">
      <validation-observer ref="createForm" novalidate>
        <div class="row">
          <div class="col-md-8">
            <validation-provider v-slot="{ errors }" rules="required|max:150">
              <base-input
                v-model="name"
                :error="errors[0]"
                :error-msg="$t('invalidField')"
                :label="$t('description')"
              />
            </validation-provider>
          </div>
          <div class="col-md-4 pt-1 text-right">
            <button class="btn btn-outline-primary mt-4" @click="onAdd()">
              <i class="far fa-plus-square" /> {{ $t('add') }}
            </button>
          </div>
        </div>
      </validation-observer>
    </div>

    <div class="col-md-12 mt-3">
      <validation-observer ref="editForm" novalidate>
        <div class="row" :class="{ 'misc-list': miscellaneous.length }">
          <div
            v-for="(item, i) in miscellaneous"
            class="col-md-12 py-2"
            :key="i"
          >
            <div v-if="indexEditing == i" class="row">
              <div class="col-md-7">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|max:150"
                >
                  <base-input
                    v-model="nameToEdit"
                    :error="errors[0]"
                    :error-msg="$t('invalidField')"
                  />
                </validation-provider>
              </div>
              <div class="col-md-5 text-right pt-2">
                <b-button
                  class="mr-2"
                  size="sm"
                  :variant="isActive ? 'warning' : 'success'"
                  @click="isActive = !isActive"
                >
                  {{ $t(isActive ? 'inactivate' : 'activate') }}
                </b-button>
                <b-button
                  class="mr-2"
                  size="sm"
                  variant="info"
                  @click="onEdit()"
                >
                  <i class="far fa-edit" />
                  {{ $t('save') }}
                </b-button>

                <b-button
                  size="sm"
                  variant="outline-danger"
                  @click="onCancel()"
                >
                  <i class="fas fa-ban" /> {{ $t('cancel') }}
                </b-button>
              </div>
            </div>
            <div v-else class="row">
              <div class="col-md-7 pt-1">
                {{ item.name }}
              </div>
              <div class="col-md-5 text-right">
                <b-button
                  class="mr-2"
                  size="sm"
                  variant="info"
                  @click="edit(item, i)"
                >
                  <i class="far fa-edit" />
                  {{ $t('edit') }}
                </b-button>
                <b-button
                  size="sm"
                  :variant="
                    item.isActive ? 'outline-success' : 'outline-warning'
                  "
                >
                  {{ $t(item.isActive ? 'active' : 'inactive') }}
                </b-button>
              </div>
            </div>
            <hr class="my-2" />
          </div>
        </div>
      </validation-observer>
    </div>
    <div class="col-md-12 text-right mt-5">
      <button class="btn btn-primary" @click="onSubmit()">
        <i class="far fa-save" /> {{ $t('save') }}
      </button>
    </div>
    <VerificationModal
      :value="showVerificationModal"
      :verification="verification"
      @cancel="onCancelVerification()"
      @continue="showVerificationModal = false"
    />
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput.vue';
import {
  getCompanyMiscellaneous as _getCompanyMiscellaneous,
  updateCompanyMiscellaneous as _updateCompanyMiscellaneous,
  checkMiscRelation as _checkMiscRelation,
} from '@/services/CompanyService';
import VerificationModal from './VerificationModal.vue';

export default {
  name: 'SpecialFieldValues',
  components: { BaseInput, VerificationModal },
  data() {
    return {
      name: null,
      nameToEdit: null,
      isActive: false,
      miscellaneous: [],
      indexEditing: null,
      showVerificationModal: false,
      verification: {
        title: '',
        message: '',
        canConfirm: false,
        isLanguageVerification: false,
        locations: [],
      },
    };
  },
  props: {
    companyId: {
      type: Number,
      required: true,
    },
    specialField: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    _getCompanyMiscellaneous(this.companyId, this.specialField).then((response) => {
      this.miscellaneous = response.data;
    });
  },
  watch: {
    isActive(value) {
      const data = this.miscellaneous[this.indexEditing];
      if (!value && data.id) {
        _checkMiscRelation(
          this.companyId,
          data.id,
        ).then(({ data }) => {
          if (data.length) {
            this.verification.title = this.$t('youWantToRemove');
            this.verification.locations = data;
            this.verification.message = this.$t('categoryWillRemovedFollowingTechnicians');
            this.verification.canConfirm = true;
            this.showVerificationModal = true;
          }

        });
      }
    },
  },
  methods: {
    edit(item, index) {
      this.indexEditing = index;
      this.nameToEdit = item.name.trim();
      this.isActive = item.isActive;
    },
    async onAdd() {
      if (!(await this.$refs.createForm.validate())) return;
      this.miscellaneous.push({ id: 0, name: this.name, isActive: true });
      this.name = null;
      await this.$refs.createForm.reset();
    },
    async onEdit() {
      if (!(await this.$refs.editForm.validate())) return;
      this.miscellaneous[this.indexEditing].name = this.nameToEdit;
      this.miscellaneous[this.indexEditing].isActive = this.isActive;
      this.onCancel();
    },
    onCancel() {
      this.nameToEdit = null;
      this.indexEditing = null;
    },
    async onSubmit() {
      await _updateCompanyMiscellaneous({
        companyId: this.companyId,
        miscellaneous: this.miscellaneous,
        specialField: this.specialField
      }).then(() => {
        this.ShowSuccessSaveToast();
        this.$emit('close');
      });
    },
    onCancelVerification() {
      this.isActive = true;
      this.showVerificationModal = false;
    },
  },
};
</script>
<style scoped lang="scss">
.misc-list {
  border: 1px solid $color-label-default !important;
}
</style>
