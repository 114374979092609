<template>
  <custom-modal
    :value="value"
    :title="verification.title"
    :no-close-on-back-drop="true"
    :hide-header-close="true"
    :no-close-on-esc="true"
    size="md"
  >
    <h5 class="mb-3">
      {{ verification.message }}
    </h5>
    <div class="row verification-content">
      <div
        v-for="(location, i) in verification.locations"
        :key="i"
        class="col-md-12"
      >
        {{ location }}
        <hr />
      </div>
    </div>
    <div class="d-flex flex-row mt-4">
      <button
        class="btn btn-secondary mx-4 flex-grow-1"
        type="button"
        @click="$emit('cancel')"
      >
        {{ $t('cancel') }}
      </button>

      <button
        v-if="verification.canConfirm"
        class="btn btn-primary mx-4 flex-grow-1"
        type="button"
        @click="$emit('continue')"
      >
        {{ $t('continue') }}
      </button>
    </div>
  </custom-modal>
</template>

<script>
import CustomModal from '@/components/basics/modal/CustomModal.vue';

export default {
  name: 'VerificationModal',
  components: {
    CustomModal,
  },
  props: {
    verification: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>

<style lang="scss" scoped>
.verification-content {
  max-height: 300px;
  overflow-y: scroll;
}
</style>
