<template>
  <validation-observer ref="form" novalidate>
    <div class="row">
      <div class="col-md-12">
        <div class="card p-4" style="border: white">
          <div class="row">
            <sub-header :title="$t('companyInformation')" />
            <div class="col-md-12"></div>
            <div class="col-md-6">
              <validation-provider v-slot="{ errors }" rules="required">
                <filter-select
                  v-model="model.organizationId"
                  :clearable="false"
                  :error="errors[0]"
                  :error-msg="$t('mustSelectOne')"
                  fieldtext="text"
                  fieldvalue="value"
                  :label="$t('organization')"
                  :options="filters.organizations"
                />
              </validation-provider>
            </div>
            <div class="col-md-6">
              <validation-provider v-slot="{ errors }" rules="required|max:200">
                <base-input
                  v-model="model.name"
                  :error="errors[0]"
                  :error-msg="$t('invalidField')"
                  :label="$t('name')"
                />
              </validation-provider>
            </div>
          </div>
        </div>
      </div>
      <div v-if="model.id" class="col-md-12 mt-3">
        <div class="card p-4" style="border: white">
          <div class="row">
            <div class="col-md-4">
              <validation-provider v-slot="{ errors }" rules="required|max:200">
                <base-input
                  v-model="model.disclaimerWith"
                  :error="errors[0]"
                  :error-msg="$t('invalidField')"
                  :label="$t('disclaimer')"
                />
              </validation-provider>
            </div>
            <div class="col-md-4">
              <validation-provider v-slot="{ errors }" rules="required">
                <filter-numeric
                  v-model="model.visitFactor"
                  :min="0"
                  :error="errors[0]"
                  :error-msg="$t('invalidField')"
                  :title="$t('visitFactor')"
                />
              </validation-provider>
            </div>
            <div class="col-md-4">
              <validation-provider v-slot="{ errors }" rules="required">
                <filter-numeric
                  v-model="model.workingDays"
                  :min="0"
                  :error="errors[0]"
                  :error-msg="$t('invalidField')"
                  :title="$t('workingDaysPerWeek')"
                />
              </validation-provider>
            </div>
            <div class="col-md-4">
              <validation-provider v-slot="{ errors }" rules="required">
                <filter-numeric
                  v-model="model.workingHours"
                  :min="0"
                  :error="errors[0]"
                  :error-msg="$t('invalidField')"
                  :title="$t('workingHoursPerDay')"
                />
              </validation-provider>
            </div>
            <div class="col-md-4">
              <base-datetime-picker
                format="dd/MMM/yyyy"
                :title="$t('finalPaymentDate')"
                type="date"
                :value="model.paymentEndDate"
                @change="model.paymentEndDate = $event"
              />
            </div>
            <div class="col-md-4">
              <filter-numeric
                v-model="model.alertDaysBeforeSuspension"
                :min="0"
                :title="$t('alertDaysBeforeSuspension')"
              />
            </div>
            <div class="col-md-4">
              <filter-numeric
                v-model="model.alertDaysAfterSuspension"
                :min="0"
                :title="$t('alertDaysAfterSuspension')"
              />
            </div>
            <div class="col-md-4">
              <base-input
                v-model="model.misc1Name"
                :label="`${$t('nameMisc')} 1`"
              />
            </div>
            <div class="col-md-4">
              <custom-image-input
                v-model="model.logo"
                :auto-delete="false"
                :file="model.logoFile"
                :label="$t('logo')"
                :max-height="512"
                :max-width="512"
                @clearFile="onDeleteImage()"
              />
            </div>
            <div class="col-md-4">
              <base-input
                v-model="model.appointmentUrl"
                :label="`${$t('appointmentUrl')}`"
              />
            </div>
            <div class="col-md-4">
              <base-input
                v-model="model.virtualUrl"
                :label="`${$t('virtualUrl')}`"
              />
            </div>
            <div class="col-md-4">
              <filter-select
                v-model="model.languageIds"
                clearable
                fieldtext="name"
                fieldvalue="id"
                :label="$t('languages')"
                :options="filters.languages"
                isMultiple
              />
            </div>
            <div class="col-md-4">
              <validation-provider
                v-slot="{ errors }"
                :rules="{
                  email: true,
                }"
              >
                <base-input
                  v-model="model.contactEmail"
                  :error="errors[0]"
                  :error-msg="$t('invalidField')"
                  :label="$t('contactEmail')"
                  :maxLength="60"
                />
              </validation-provider>
            </div>
            <div class="col-md-4">
              <base-input
                v-model="model.contactPhone"
                :label="`${$t('contactPhone')}`"
                :maxLength="15"
                mask-type="Phone"
              />
            </div>
            <div class="col-md-4">
            </div>
            <div class="col-md-4">
              <b-form-checkbox v-model="model.filterBySpecialist" switch>
                {{ $t('coordinatorFilter') }}
              </b-form-checkbox>
              <b-form-checkbox v-model="model.belongsToGovernment" switch>
                {{ $t('belongsToGovernment') }}
              </b-form-checkbox>
              <b-form-checkbox
                v-model="model.videoCallNotificationOnCreateTurn"
                switch
              >
                {{ $t('videoCallNotificationOnCreateTurn') }}
              </b-form-checkbox>
              <b-form-checkbox
                v-model="model.disableEmailRequiredForAppointmentCoordination"
                switch
              >
                {{ $t('disableEmailRequiredForAppointmentCoordination') }}
              </b-form-checkbox>
              <b-form-checkbox v-model="model.resendSurveys" switch>
                {{ $t('resendUnansweredSurveys') }}
              </b-form-checkbox>
              <b-form-checkbox
                v-model="model.allowCurrentDayCoordination"
                switch
              >
                {{ $t('allowCurrentDayCoordination') }}
              </b-form-checkbox>
            </div>
            <div class="col-md-4">
              <b-form-checkbox
                v-model="model.showLocationTimeInformation"
                switch
              >
                {{ $t('showLocationTimeInformation') }}
              </b-form-checkbox>
              <b-form-checkbox
                v-if="model.showLocationTimeInformation"
                v-model="model.showServiceAverageTime"
                switch
              >
                {{ $t('showServiceAverageTime') }}
              </b-form-checkbox>
              <b-form-checkbox
                v-if="model.showLocationTimeInformation"
                v-model="model.showLocationTurns"
                switch
              >
                {{ $t('showLocationTurns') }}
              </b-form-checkbox>
              <b-form-checkbox
                v-if="model.showLocationTimeInformation"
                v-model="model.showLocationAddress"
                switch
              >
                {{ $t('showLocationAddress') }}
              </b-form-checkbox>
              <b-form-checkbox
                v-if="model.showLocationTimeInformation"
                v-model="model.showMileage"
                switch
              >
                {{ $t('showMileage') }}
              </b-form-checkbox>
              <b-form-checkbox
                v-if="model.showLocationTimeInformation"
                v-model="model.showHowToArrive"
                switch
              >
                {{ $t('showHowToArrive') }}
              </b-form-checkbox>
              <b-form-checkbox
                v-model="model.displayAppointmentCoordinationDate"
                switch
              >
                {{ $t('displayAppointmentCoordinationDate') }}
              </b-form-checkbox>
            </div>
            <div class="col-md-4">
              <b-form-checkbox v-model="model.enabled" switch>
                {{ $t('available') }}
              </b-form-checkbox>
              <b-form-checkbox v-model="model.misc1" switch>
                {{ $t('miscellaneous') }} 1
              </b-form-checkbox>
              <b-form-checkbox v-model="model.allowEntryByInitialPage" switch>
                {{ $t('showAppointmentsPlusToPublic') }}
              </b-form-checkbox>
              <b-form-checkbox v-model="model.enableMSTeams" switch>
                {{ $t('activateMSTeams') }}
              </b-form-checkbox>
              <b-form-checkbox
                class="mt-3"
                v-model="model.enableMiscDropDown"
                switch
              >
                {{ $t('showCategory') }}
              </b-form-checkbox>
              <base-input
                v-model="model.miscDropDownName"
                :label="`${$t('categoryName')}`"
                style="width: 300px"
              />
              <button
                class="btn btn-outline-info"
                @click="openSpecialFieldModal(9)"
              >
                <i class="fas fa-plus fa-lg"></i> {{ $t('values') }}
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-6 mt-2">
              <div class="splitter mb-4" />
              <div class="col-12">
                <b-form-checkbox
                  v-model="model.communicationModuleEnabled"
                  switch
                >
                  {{ $t('enableCommunicationModule') }}
                </b-form-checkbox>
              </div>
              <div class="col-12 mb-2">
                <b-form-checkbox
                  v-model="showRepresentativeCompanionType"
                  :disabled="!model.communicationModuleEnabled"
                  switch
                >
                  {{ $t('representativeCompanionTypeRequired') }}
                </b-form-checkbox>
              </div>
              <div
                v-if="showRepresentativeCompanionType"
                class="col-sm-12 col-md-8"
              >
                <validation-provider v-slot="{ errors }" rules="required">
                  <filter-select
                    v-model="model.representativeCompanionType"
                    fieldtext="text"
                    fieldvalue="value"
                    :disabled="!model.communicationModuleEnabled"
                    :error="errors[0]"
                    :error-msg="$t('mustSelectOne')"
                    :label="$t('type')"
                    :options="representativeCompanionTypeOptions"
                  />
                </validation-provider>
              </div>
              <div class="col-sm-12 col-md-8">
                <filter-numeric
                  v-model="model.communicationMaxRecallCount"
                  :disabled="!model.communicationModuleEnabled"
                  :min="0"
                  :title="$t('maxRecall')"
                />
              </div>
            </div>
            <div class="col-sm-12 col-md-6 mt-2">
              <div class="splitter mb-4" />
              <div class="col-12 mb-2">
                <b-form-checkbox v-model="model.isPremium" switch>
                  {{ $t('isPremium') }}
                </b-form-checkbox>
              </div>
              <div class="col-12">
                <base-input
                  v-model="model.premiumSiteUrl"
                  :label="`${$t('premiumSiteUrl')}`"
                />
              </div>
            </div>
          </div>
          <div class="pb-4"></div>
        </div>
      </div>
      <div v-if="model.id" class="col-md-12">
        <div class="row">
          <div class="col-12 my-4">
            <h4 class="font-weight-bolder float-left">
              {{ $t('extraField1100') }}
            </h4>
            <hr />
          </div>
          <div class="col-md-12">
            <div class="card border-light p-4">
              <div class="row">
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-12">
                      <b-form-checkbox v-model="model.specialField1" switch>
                        {{ $t('activateSpecialField') }} 1
                      </b-form-checkbox>
                    </div>
                    <div class="col-md-12">
                      <base-input
                        v-model="model.specialField1Name"
                        :label="`${$t('specialFieldName')} 1`"
                      />
                    </div>
                    <div class="col-md-12">
                      <b-form-checkbox
                        v-model="model.specialField1Required"
                        switch
                      >
                        {{ $t('required') }}
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-12">
                      <b-form-checkbox v-model="model.specialField2" switch>
                        {{ $t('activateSpecialField') }} 2
                      </b-form-checkbox>
                    </div>
                    <div class="col-md-12">
                      <base-input
                        v-model="model.specialField2Name"
                        :label="`${$t('specialFieldName')} 2`"
                      />
                    </div>
                    <div class="col-md-12">
                      <b-form-checkbox
                        v-model="model.specialField2Required"
                        switch
                      >
                        {{ $t('required') }}
                      </b-form-checkbox>
                    </div>
                    <div class="col-12">
                      <button
                        class="btn btn-outline-info"
                        @click="openSpecialFieldModal(2)"
                      >
                        <i class="fas fa-plus fa-lg"></i> {{ $t('values') }}
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-12">
                      <b-form-checkbox v-model="model.specialField3" switch>
                        {{ $t('activateSpecialField') }} 3
                      </b-form-checkbox>
                    </div>
                    <div class="col-md-12">
                      <base-input
                        v-model="model.specialField3Name"
                        :label="`${$t('specialFieldName')} 3`"
                      />
                    </div>
                    <div class="col-md-12">
                      <b-form-checkbox
                        v-model="model.specialField3Required"
                        switch
                      >
                        {{ $t('required') }}
                      </b-form-checkbox>
                    </div>
                    <div class="col-12">
                      <button
                        class="btn btn-outline-info"
                        @click="openSpecialFieldModal(3)"
                      >
                        <i class="fas fa-plus fa-lg"></i> {{ $t('values') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12 my-5 text-right">
        <base-filled-button
          bg-color="#707070"
          class="mx-0 float-left"
          icon-class="fas fa-ban"
          :on-click="() => $router.push({ name: 'Companies' })"
          :text="$t('cancel')"
        />

        <button
          class="btn btn-outline-primary"
          variant="outline-primary"
          @click="onSubmit()"
        >
          <i class="far fa-save" /> {{ $t('save') }}
        </button>

        <button
          v-if="model.id"
          class="btn btn-primary ml-2"
          @click="onSubmit(true)"
        >
          <i class="far fa-arrow-alt-circle-right" /> {{ $t('saveContinue') }}
        </button>
      </div>
    </div>
    <custom-modal
      v-model="showSpecialFieldModal"
      :title="$t('categoryFieldValues')"
      size="lg"
    >
      <SpecialFieldValues
        v-if="showSpecialFieldModal"
        :company-id="model.id"
        :special-field="selectedSpecialField"
        @close="showSpecialFieldModal = false"
      />
    </custom-modal>
    <VerificationModal
      :value="showVerificationModal"
      :verification="verification"
      @cancel="onCancelVerification()"
      @continue="showVerificationModal = false"
    />
  </validation-observer>
</template>

<script>
import BaseInput from '@/components/BaseInput';
import SubHeader from '@/components/SubHeader';
import { getOrganizations as _getOrganizations } from '@/services/OrganizationService';
import { getLanguages as _getLanguages } from '@/services/CodeService';
import {
  createCompany as _createCompany,
  updateCompany as _updateCompany,
  deleteImage as _deleteImage,
  checkLanguageRelation as _checkLanguageRelation,
} from '@/services/CompanyService';
import CustomImageInput from '@/components/CustomImageInput';
import CustomModal from '@/components/basics/modal/CustomModal.vue';
import SpecialFieldValues from './components/SpecialFieldValues.vue';
import VerificationModal from './components/VerificationModal.vue';
import RepresentativeCompanionTypeOptions from '@/constants/RepresentativeCompanionTypeOptions';

export default {
  name: 'CompanyCreate',
  components: {
    BaseInput,
    SubHeader,
    CustomImageInput,
    CustomModal,
    SpecialFieldValues,
    VerificationModal,
  },
  props: {
    model: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      filters: {
        organizations: [],
        languages: [],
      },
      showSpecialFieldModal: false,
      showVerificationModal: false,
      selectedSpecialField: null,
      verification: {
        title: '',
        message: '',
        canConfirm: false,
        isLanguageVerification: false,
        locations: [],
        id: null,
      },
      showRepresentativeCompanionType: false,
      representativeCompanionTypeOptions: RepresentativeCompanionTypeOptions,
    };
  },
  computed: {},
  watch: {
    'model.languageIds'(curr, prev) {
      if (this.model.id && curr.length < prev.length) {
        const languageId = prev.find((x) => !curr.includes(x));
        _checkLanguageRelation(this.model.id, languageId).then(({ data }) => {
          if (data.locations.length) {
            let title = 'youWantToRemove';
            let message = 'languageWillRemovedFollowingTechnicians';
            if (data.isTurnValidation) {
              title = 'cantRemoveLanguage';
              message = 'cantRemoveLanguageUntilEndThesTurns';
            } else if (data.isPreferentialValidation) {
              message = 'preferenceWillRemovedFollowingLocation';
            }
            this.verification.title = this.$t(title);
            this.verification.message = this.$t(message);
            this.verification.canConfirm = !data.isTurnValidation;
            this.verification.isLanguageVerification = true;
            this.verification.locations = data.locations;
            this.verification.id = languageId;
            this.showVerificationModal = true;
          }
        });
      }
    },
    model() {
      this.showRepresentativeCompanionType = !!this.model.representativeCompanionType;
    }
  },
  async mounted() {
    await _getOrganizations()
      .then((response) => {
        this.filters.organizations = response.data || [];
      })
      .catch((error) => this.ShowErrorToast(error.response.data.message));
    await _getLanguages().then(({ data }) => {
      this.filters.languages = data || [];
    });
  },
  methods: {
    async onSubmit(next = false) {
      if (!(await this.$refs.form.validate())) return;

      const request = this.model.id ? _updateCompany : _createCompany;
      this.model.representativeCompanionType = this
        .showRepresentativeCompanionType
        ? this.model.representativeCompanionType
        : null;

      await request(this.model).then((response) => {
        if (!this.model.id)
          this.$router.push({
            name: 'CompanyEdit',
            params: { id: response.data },
          });

        if (next) this.$emit('go-to', 1);
        this.ShowSuccessSaveToast();
      });
    },
    openSpecialFieldModal(specialField) {
      this.selectedSpecialField = specialField;
      this.showSpecialFieldModal = true;
    },
    async onDeleteImage() {
      _deleteImage(this.model.id)
        .then(() => {
          this.model.logo = null;
          this.model.logoFile = null;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onCancelVerification() {
      if (this.verification.isLanguageVerification) {
        this.model.languageIds.push(this.verification.id);
      }
      this.showVerificationModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.splitter {
  border: 1px solid lightgray;
  width: 100%;
  margin: 10px 0;
}
</style>
