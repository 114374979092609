<template>
  <validation-observer ref="notificationProviderFormRef" novalidate>
    <div class="row">
      <div class="col-sm-3">
        <label>{{ $t('sender') }}</label>
      </div>
      <div class="col-sm-4">
        <label>{{ $t('appointmentModule') }}</label>
      </div>
      <div class="col-sm-4">
        <label>{{ $t('turnModule') }}</label>
      </div>
    </div>
    <div v-for="(item, index) in notificationProviderCarriers" :key="index" class="row">
      <div class="col-sm-3">
        <filter-select
            fieldtext="name"
            fieldvalue="id"
            :label="''"
            :is-multiple="false"
            :options="collections.notificationProviders"
            :placeholder="$t('select')"
            :value="item.notificationProviderId"
            @change="OnNotificationProviderChange($event, index)"
          />
      </div>
      <div class="col-sm-4">
        <filter-select
            fieldtext="name"
            fieldvalue="id"
            :label="''"
            :is-multiple="true"
            :options="collections.appointmentCarriers"
            :placeholder="$t('select')"
            v-model="item.appointmentCarrierIds"
            :allow-select-all="false"
            @change="OnAppointmentCarrierChange(item)"
          />
      </div>
      <div class="col-sm-4">
        <filter-select
            fieldtext="name"
            fieldvalue="id"
            :label="''"
            :is-multiple="true"
            :options="collections.turnCarriers"
            :placeholder="$t('select')"
            v-model="item.turnCarrierIds"
            :allow-select-all="false"
            @change="OnTurnCarrierChange(item)"
          />
      </div>
      <div class="col-sm-1">
        <b-button
          class="btn-actions mt-2"
          size="sm"
          variant="outline-danger"
          @click="removeNotificationProvider(index)"
        >
          <i class="far fa-trash-alt" />
        </b-button>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <b-button
          class="float-left"
          variant="outline-success"
          @click="OnAddRecord"
        >
          <i class="fal fa-add" /> {{ $t('add') }}
        </b-button>
      </div>
      <div class="col-sm-12 col-md-6">
        <button
          class="btn btn-primary float-right"
          variant="primary"
          @click="onSubmit()"
        >
          <i class="far fa-save" /> {{ $t('save') }}
        </button>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import { 
  updateNotificationProviders as _updateNotificationProviders,
  getInitNotificationProviders as _getInitNotificationProviders 
} from '@/services/CompanyService';

export default {
  name: 'NotificationProviderForm',
  components: { },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {  
      company: this.value,
      collections: {
        notificationProviders: [],
        appointmentCarriers: [],
        turnCarriers: [],
      },
      notificationProviderCarriers: []
    };
  },
  computed: {
    availableNotificationProviders() {
      return this.collections.notificationProviders.filter(x => !this.notificationProviderCarriers.some(r => x.id == r.notificationProviderId));
    },
  },
  async mounted() {
    await _getInitNotificationProviders(this.company.id).then((resp) => {
      this.collections.notificationProviders = resp.data.collections.notificationProviders;
      this.collections.appointmentCarriers = resp.data.collections.appointmentCarriers;
      this.collections.turnCarriers = resp.data.collections.turnCarriers;

      if(resp.data.notificationProviderCarriers.length) {
        this.notificationProviderCarriers = resp.data.notificationProviderCarriers;
      }
      else
      this.notificationProviderCarriers = [{
        notificationProviderId: null,
        appointmentCarrierIds: [],
        turnCarrierIds: []
      }];
    })
    .catch((error) => this.ShowErrorToast(error.response.data.message));
  },
  methods: {
    async OnAddRecord() {
      this.notificationProviderCarriers.push({
        notificationProviderId: null,
        appointmentCarrierIds: [],
        turnCarrierIds: []
      });
    },
    async OnNotificationProviderChange(item, index) {
      this.notificationProviderCarriers[index].notificationProviderId = item ? item.id : null;

      await this.$nextTick(async () => {
        let selectedNotificationProviderIds = [];

        for (let i = 0; i < this.notificationProviderCarriers.length; i++) {
          selectedNotificationProviderIds.push(this.notificationProviderCarriers[i].notificationProviderId);
        }
        
        const mostRecentId = selectedNotificationProviderIds[selectedNotificationProviderIds.length - 1];

        const hasExistingProviderId = selectedNotificationProviderIds.filter((x) => x == mostRecentId).length > 1;

        if(hasExistingProviderId) {
           const idxToRemove = selectedNotificationProviderIds.lastIndexOf(mostRecentId);

           this.notificationProviderCarriers[idxToRemove].notificationProviderId = null;
        }
      });
    },
    async OnAppointmentCarrierChange(record) {
      await this.$nextTick(async () => {
        let selectedAppointmentCarrierIds = [];

        for (let i = 0; i < this.notificationProviderCarriers.length; i++) {
          selectedAppointmentCarrierIds = selectedAppointmentCarrierIds.concat([...this.notificationProviderCarriers[i].appointmentCarrierIds]);
        }
        
        const mostRecentId = selectedAppointmentCarrierIds[selectedAppointmentCarrierIds.length - 1];

        const hasExistingCarrierId = selectedAppointmentCarrierIds.filter((x) => x == mostRecentId).length > 1;

        if(hasExistingCarrierId) {
           const idxToRemove = record.appointmentCarrierIds.lastIndexOf(mostRecentId);

           record.appointmentCarrierIds.splice(idxToRemove, 1);

           this.ShowErrorToast(this.$t('senderAppointmentCarrierDuplicityError'));
        }
      });
    },
    async OnTurnCarrierChange(record) {
      await this.$nextTick(async () => {
        let selectedTurnCarrierIds = [];

        for (let i = 0; i < this.notificationProviderCarriers.length; i++) {
          selectedTurnCarrierIds = selectedTurnCarrierIds.concat([...this.notificationProviderCarriers[i].turnCarrierIds]);
        }
        
        const mostRecentId = selectedTurnCarrierIds[selectedTurnCarrierIds.length - 1];

        const hasExistingCarrierId = selectedTurnCarrierIds.filter((x) => x == mostRecentId).length > 1;

        if(hasExistingCarrierId) {
           const idxToRemove = record.turnCarrierIds.lastIndexOf(mostRecentId);

           record.turnCarrierIds.splice(idxToRemove, 1);

           this.ShowErrorToast(this.$t('senderTurnCarrierDuplicityError'));
        }
      });
    },
    async removeNotificationProvider(index) {
      this.notificationProviderCarriers.splice(index, 1);
    },
    async onSubmit() {
      let payload = [];

      for (let i = 0; i < this.notificationProviderCarriers.length; i++) {
        for (let j = 0; j < this.notificationProviderCarriers[i].appointmentCarrierIds.length; j++) {
          payload.push({
            notificationProviderId: this.notificationProviderCarriers[i].notificationProviderId,
            appointmentCarrierId: this.notificationProviderCarriers[i].appointmentCarrierIds[j]
          });
        }

        for (let j = 0; j < this.notificationProviderCarriers[i].turnCarrierIds.length; j++) {
          payload.push({
            notificationProviderId: this.notificationProviderCarriers[i].notificationProviderId,
            turnCarrierId: this.notificationProviderCarriers[i].turnCarrierIds[j]
          });
        }
      }

      await _updateNotificationProviders(this.company.id, payload)
        .then(() => {
          this.ShowSuccessSaveToast();
          this.$emit('submitted');
          this.$emit('close');
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
