<template>
  <div class="mt-5 container-fluid">
    <step-wizard
      class="mb-3"
      :step="stepIndex"
      :tabs="tabs"
      @OnStepChange="onStepChange($event)"
    />
    <ul id="tab-controls" class="nav nav-pills" role="tablist">
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: isActive(0) }"
          @click.prevent="onStepChange(0)"
        />
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: isActive(1) }"
          @click.prevent="onStepChange(1)"
        />
      </li>
    </ul>
    <div id="tab-container">
      <div
        class="tab-pane fade"
        :class="{ 'active show': isActive(0) }"
        role="tabpanel"
      >
        <Create :model="model" @go-to="onStepChange($event)" />
      </div>
      <div
        class="tab-pane fade"
        :class="{ 'active show': isActive(1) }"
        role="tabpanel"
      >
        <OtherMembers
          v-if="isActive(1)"
          :company-id="model.id"
          @go-to="onStepChange($event)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import StepWizard from '@/components/StepWizard';
import Create from './Create';
import OtherMembers from './OtherMembers';
import { getCompany as _getCompany } from '@/services/CompanyService';

export default {
  name: 'CompanyMaster',
  components: {
    StepWizard,
    Create,
    OtherMembers,
  },
  data() {
    return {
      stepIndex: 0,
      loading: false,
      model: {
        id: 0,
        name: null,
        disclaimerWith: null,
        visitFactor: null,
        workingDays: null,
        workingHours: null,
        waitingTimeHours: null,
        paymentEndDate: null,
        alertDaysBeforeSuspension: null,
        alertDaysAfterSuspension: null,
        enabled: false,
        misc1: false,
        misc1Name: null,
        filterBySpecialist: false,
        logo: null,
        logoFile: null,
        belongsToGovernment: false,
        videoCallNotificationOnCreateTurn: false,
        disableEmailRequiredForAppointmentCoordination: false,
        allowCurrentDayCoordination: false,
        showLocationTimeInformation: false,
        showServiceAverageTime: false,
        showLocationTurns: false,
        showLocationAddress: false,
        showMileage: false,
        showHowToArrive: false,
        isPremium: false,
        premiumSiteUrl: null,
        allowEntryByInitialPage: false,
        enableMSTeams: false,
        languageIds: [],
        specialField1: false,
        specialField1Name: null,
        specialField1Required: false,
        specialField2: false,
        specialField2Name: null,
        specialField2Required: false,
        specialField3: false,
        specialField3Name: null,
        specialField3Required: false,
        displayAppointmentCoordinationDate: false
      },
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(val) {
        if (val.name !== 'CompanyCreate') {
          this.model.id = Number(val.params.id);
          _getCompany(this.model.id).then((response) => {
            this.model = response.data;
            this.model.logo = null;
          });
          if (val.name === 'CompanyAddMembers') this.stepIndex = 1;
        }
      },
    },
  },
  computed: {
    tabs() {
      if (!this.model.id) {
        return [
          {
            name: this.$t('companyMaintenance'),
            icon: 'fas fa-plus',
            disabled: false,
          },
        ];
      }
      return [
        {
          name: this.$t('companyMaintenance'),
          icon: 'fas fa-plus',
          disabled: false,
        },
        {
          name: this.$t('otherMembers'),
          icon: 'far fa-lock',
          disabled: false,
        },
      ];
    },
  },
  methods: {
    isActive(step) {
      return this.stepIndex == step;
    },
    onStepChange(step) {
      this.stepIndex = step;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .nav,
.nav-pills {
  display: none;
}
.tab-pane {
  display: none;
}
.tab-pane.active.show {
  display: block;
}
</style>
