<template>
  <div>
    <content-header :title="$t('usersMaintenance')" />
    <div class="container-fluid">
      <div class="main-page-container">
        <div class="row">
          <sub-header :title="$t('filterusers')" />
        </div>
        <div class="row">
          <div class="col-md-4">
            <filter-select
              v-model="selectedFilters.roleIds"
              fieldtext="name"
              fieldvalue="id"
              :label="$t('roles')"
              :options="filters.roles"
              :placeholder="$t('select')"
              :is-multiple="true"
            />
          </div>
          <div v-if="!haveCompany" class="col-md-4">
            <filter-select
              v-model="selectedFilters.companyId"
              fieldtext="text"
              fieldvalue="value"
              :label="$t('company')"
              :options="filters.companies"
              :placeholder="$t('select')"
            />
          </div>
          <div class="col-md-4">
            <filter-select
              v-model="selectedFilters.locationConfigurationId"
              fieldtext="name"
              fieldvalue="id"
              :label="$t('location')"
              :options="filters.locations"
              :placeholder="$t('select')"
            />
          </div>
          <div class="col-md-4">
            <base-input
              v-model="selectedFilters.user"
              :label="$t('userEmail')"
              :placeholder="$t('userEmail')"
            />
          </div>
          <div class="col-md-4">
            <filter-select
              v-model="selectedFilters.isActive"
              fieldtext="text"
              fieldvalue="value"
              :label="$t('status')"
              :options="filters.status"
              :placeholder="$t('select')"
            />
          </div>
          <div class="col-md-4 mt-4">
            <base-switch
              v-model="selectedFilters.hideInactiveUsers"
              :label="$t('hideInactiveUsersFilter')"
            />
          </div>
          <div class="col text-right mt-4">
            <button
              class="btn btn-primary mr-3 pl-3"
              @click="getUserDataDocument()"
            >
              <i class="fa fa-search" /> {{ $t('export') }}
            </button>
            <button class="btn btn-primary" @click="getUsers(1)">
              <i class="fa fa-search" /> {{ $t('search') }}
            </button>
          </div>
        </div>
      </div>

      <separator
        class="mt-4 mb-3 pl-0 pr-0"
        content-class="separator-custom-content"
        :text="$t('userslist')"
      />
      <div v-if="users.data.length" class="row">
        <div
          v-for="(user, index) in users.data"
          :key="index"
          class="col-md-12 mt-3"
        >
          <search-result
            :value="user"
            @assign="assignLocation(user)"
            @block="lockOrUnlock(user.userId)"
          />
        </div>
      </div>

      <custom-pagination
        v-if="users.count > 0"
        id="pgPager"
        :data="users"
        :page-size="pageSize"
        @pagination-go-page="getUsers($event)"
        @pagination-rows-per-page="getUsers(pageIndex, $event)"
      />
      <CustomNotFound v-if="noResultsFound" :text="$t('noResultsFound')" />
    </div>
    <custom-modal v-model="showAssignOfficeModal" size="lg">
      <AssignOfficesUser
        v-if="showAssignOfficeModal"
        :user="user"
        @close="showAssignOfficeModal = false"
        @on-saved="getUsers()"
        :all-locations="!haveCompany"
      />
    </custom-modal>
  </div>
</template>

<script>
import SystemRoles from '@/constants/SystemRoles';
import Separator from '@/components/Separator';
import SearchResult from './components/SearchResult';
import CustomPagination from '@/components/CustomPagination.vue';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import AssignOfficesUser from '@/components/AssignOfficesUser';
import ContentHeader from '@/components/Header';
import SubHeader from '@/components/SubHeader.vue';
import CustomModal from '@/components/basics/modal/CustomModal.vue';
import { mapGetters } from 'vuex';
import BaseInput from '@/components/BaseInput.vue';
import { getRoleList as _getRoleList } from '@/services/RoleService';
import { getCompanies as _getCompanies } from '@/services/CompanyService';
import { getLocationList as _getLocationList } from '@/services/LocationService';
import { modulesName } from '@/store';
import {
  getUsersForCompany as _getUsers,
  lockOrUnlock as _lockOrUnlock,
  getUserDataDocument as _getUserDataDocument,
} from '@/services/UserService';
import _generalApi from '@/modules/app/api.js';
import BaseSwitch from '@/components/BaseSwitch.vue';

export default {
  name: 'CompanyUsers',
  components: {
    Separator,
    SearchResult,
    CustomPagination,
    ContentHeader,
    SubHeader,
    BaseInput,
    CustomNotFound,
    AssignOfficesUser,
    CustomModal,
    BaseSwitch,
  },
  data() {
    return {
      filters: {
        locations: [],
        companies: [],
        roles: [],
        status: [],
      },
      selectedFilters: {
        roleIds: [],
        locationConfigurationId: null,
        companyId: null,
        user: null,
        isActive: null,
        hideInactiveUsers: false,
      },
      users: {
        data: [],
        totalItemCount: 0,
        count: 0,
        pageCount: 0,
        currentPage: 1,
      },
      noResultsFound: false,
      pageSize: 10,
      pageIndex: 1,
      showAssignOfficeModal: false,
      user: null,
    };
  },
  computed: {
    ...mapGetters(modulesName.userModuleName, [
      'roleId',
      'currentLocations',
      'companies',
    ]),
    haveCompany() {
      return this.companies && Object.keys(this.companies).length;
    },
  },
  watch: {
    'selectedFilters.companyId'(value) {
      this.filters.locations = [];
      if (!value) return;
      this.getAvailableLocalizations(value);
    },
  },
  mounted() {
    this.filters.status = [
      { value: false, text: this.$t('blocked') },
      { value: true, text: this.$t('unlocked') },
    ];
    this.loadInitialFilters();
  },
  methods: {
    async loadInitialFilters() {
      await _getRoleList()
        .then((response) => {
          let roles = response.data || [];

          if (this.haveCompany) {
            roles = roles.filter((x) =>
              [
                SystemRoles.clientuser,
                SystemRoles.client,
                SystemRoles.localuseradministrator,
                SystemRoles.moderator,
                SystemRoles.clientlist,
                SystemRoles.citas,
              ].includes(x.id),
            );
          }

          this.filters.roles = roles;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));

      if (this.haveCompany)
        this.filters.locations = this.currentLocations.map((x) => ({
          name: x.name,
          id: +x.locationConfigurationId,
        }));
      else {
        await _getCompanies()
          .then((response) => {
            this.filters.companies = response.data || [];
          })
          .catch((error) => this.ShowErrorToast(error.response.data.message));
      }
    },
    async getAvailableLocalizations(companyId) {
      await _getLocationList(companyId)
        .then((response) => {
          this.filters.locations = response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getUsers(pageIndex = null, pageSize = null) {
      this.pageSize = pageSize || this.pageSize;
      this.pageIndex = pageIndex || this.pageIndex;

      await _getUsers({
        ...this.selectedFilters,
        isActive: this.selectedFilters.hideInactiveUsers
          ? this.selectedFilters.hideInactiveUsers
          : null,
        isApproved: this.selectedFilters.hideInactiveUsers
          ? this.selectedFilters.hideInactiveUsers
          : null,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      })
        .then(({ data }) => {
          this.users = data;
          this.filtered = true;

          this.noResultsFound = !this.users.data.length;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    lockOrUnlock(userId) {
      _lockOrUnlock(userId)
        .then(() => {
          this.ShowSuccessSaveToast();
          this.getUsers();
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    assignLocation(user) {
      this.user = user;
      this.showAssignOfficeModal = true;
    },
    async getUserDataDocument() {
      const payload = {
        roleIds:
          this.selectedFilters.roleIds.length > 0
            ? this.selectedFilters.roleIds
            : null,
        companyId: this.selectedFilters.companyId || null,
        locationConfigurationId:
          this.selectedFilters.locationConfigurationId || null,
        user: this.selectedFilters.user || null,
        isActive: this.selectedFilters.isActive || null,
      };

      // At least 2 parameters must be selected
      var cantFilter = 0;
      if (payload.roleIds) cantFilter++;
      if (payload.roleIds && payload.roleIds.length > 1) cantFilter++;
      if (payload.companyId) cantFilter++;
      if (payload.locationConfigurationId) cantFilter++;
      if (payload.user) cantFilter++;
      if (payload.isActive) cantFilter++;

      if (cantFilter < 2) {
        this.ShowToast(
          'Error',
          `${this.$t('userCompleteParameterInformation')}`,
          'error',
        );
        return;
      }

      await _getUserDataDocument(payload)
        .then((response) => _generalApi.ConvertToBlob(response.data))
        .catch((error) => {
          if (error.response.data.message) {
            this.ShowToast('Error', error.response.data.message, 'error');
          } else {
            this.ShowToast('Error', error.message, 'error');
          }
        });
    },
  },
};
</script>
