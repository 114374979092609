import { render, staticRenderFns } from "./SSOForm.vue?vue&type=template&id=314548e9&scoped=true&"
import script from "./SSOForm.vue?vue&type=script&lang=js&"
export * from "./SSOForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "314548e9",
  null
  
)

export default component.exports