<template>
  <section>
    <content-header :title="$t('companiesMaintenance')" />
    <div class="container-fluid">
      <div class="main-page-container">
        <div class="row">
          <sub-header :title="$t('filtersCompanies')" />
        </div>
        <filter-manager
          ref="filterComponent"
          v-model="selectedFilters"
          class="mb-4"
          :display-advanced="false"
          :filters="filtersConfig"
          :search-text="$t('filter')"
          @search="getCompanies(1)"
        />
      </div>

      <separator class="my-4" :text="$t('listOfCompanies')" />
      <div class="row mt-4 mb-3">
        <div class="col-sm-12">
          <base-filled-button
            class="float-right"
            icon-class="fas fa-plus"
            :text="$t('create')"
            :on-click="() => $router.push({ name: 'CompanyCreate' })"
          />
        </div>
      </div>
      <CustomNotFound v-if="noResultsFound" :text="$t('noResultsFound')" />
      <div class="row">
        <div
          v-for="(company, index) in companies.data"
          :key="index"
          class="mt-3 col-12"
        >
          <search-result
            :value="company"
            @add-non-business-days="openAddNonWorkingDaysModal(company)"
            @on-notification="openNotificationModal(company)"
            @remove-non-business-days="openRemoveNonWorkingDaysModal(company)"
            @on-sso="openSSOModal(company)"
            @on-sync-chat-users="showSyncChatUsersConfirmation(company)"
          />
        </div>
      </div>
      <custom-pagination
        v-if="companies.count > 0"
        id="pgPager"
        class="my-4"
        :data="companies"
        :page-size="pageSize"
        @pagination-go-page="getCompanies($event)"
        @pagination-rows-per-page="getCompanies(pageIndex, $event)"
      />
    </div>
    <custom-modal
      v-model="showNotificationFormModal"
      size="lg"
      :title="`${selectedCompany.name} - ${$t('notificationManagement')}`"
    >
      <notification-provider-form
        v-if="showNotificationFormModal"
        v-model="selectedCompany"
        @close="showNotificationFormModal = false"
      />
    </custom-modal>
    <custom-modal
      v-model="showAddNonWorkingDaysModal"
      size="lg"
      :title="$t('addNonBusinessDays')"
    >
      <AddNonWorkingDays
        v-if="showAddNonWorkingDaysModal"
        :company-id="selectedCompany.id"
        @close="showAddNonWorkingDaysModal = false"
      />
    </custom-modal>
    <custom-modal
      v-model="showRemoveNonWorkingDaysModal"
      size="lg"
      :title="$t('removeNonBusinessDays')"
    >
      <RemoveNonWorkingDays
        v-if="showRemoveNonWorkingDaysModal"
        :company-id="selectedCompany.id"
        @close="showRemoveNonWorkingDaysModal = false"
      />
    </custom-modal>

    <custom-modal
      v-model="showSSOModal"
      size="lg"
      :title="$t('singlesignon')"
    >
      <SSOForm
        v-if="showSSOModal"
        v-model="selectedCompany"
        @close="showSSOModal = false"
      />
    </custom-modal>

    <confirmation-modal
      v-model="showSyncChatModal"
      text="syncChatUsersConfimation"
      @on-confirm="syncChatUsers()"
      @on-reject="showSyncChatModal = false"
    />
  </section>
</template>

<script>

import sessionState from '@/mixins/sessionState';
import CustomPagination from '@/components/CustomPagination';
import CustomModal from '@/components/basics/modal/CustomModal';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import SearchResult from './components/SearchResult';
import AddNonWorkingDays from './components/AddNonWorkingDays';
import RemoveNonWorkingDays from './components/RemoveNonWorkingDays';
import FilterManager from '@/components/FilterManager';
import contentHeader from '@/components/Header';
import SubHeader from '@/components/SubHeader';
import Separator from '@/components/Separator';
import NotificationProviderForm from './components/NotificationProviderForm';
import SSOForm from './components/SSOForm';

import { getCompaniesList as _getCompaniesList } from '@/services/CompanyService';
import { getOrganizations as _getOrganizations } from '@/services/OrganizationService';

import ConfirmationModal from '@/components/basics/modal/ConfirmationModal.vue';

import {
  syncChatUserAccounts as _syncChatUserAccounts
} from '@/services/UserService'

export default {
  name: 'Companies',
  components: {
    CustomPagination,
    CustomNotFound,
    SearchResult,
    FilterManager,
    contentHeader,
    SubHeader,
    Separator,
    CustomModal,
    NotificationProviderForm,
    AddNonWorkingDays,
    RemoveNonWorkingDays,
    SSOForm,
    ConfirmationModal
  },
  mixins: [sessionState],
  data() {
    return {
      filters: {
        organizations: [],
      },
      selectedFilters: {
        organization: [],
        name: '',
      },
      companies: {
        data: [],
        totalItemCount: 0,
        count: 0,
        pageCount: 0,
        currentPage: 1,
      },
      pageSize: 10,
      pageIndex: 1,
      noResultsFound: false,
      showNotificationFormModal: false,
      showAddNonWorkingDaysModal: false,
      showRemoveNonWorkingDaysModal: false,
      showSSOModal: false,
      selectedCompany: {},
      showSyncChatModal: false
    };
  },
  mounted() {
    // load current state of this view
    const previousStateData = this.loadAndRemoveSessionData('companiesData');

    if (previousStateData != null) {
      Object.assign(this.$data, previousStateData);
    } else {
      this.getOrganizations();
    }
  },
  computed: {
    filtersConfig() {
      return [
        {
          rules: '',
          name: 'organization',
          label: this.$t('organization'),
          component: 'FilterMultiSelect',
          options: this.filters.organizations,
          placeholder: `-- ${this.$t('all')} --`,
          fieldtext: 'text',
          fieldvalue: 'value',
          clearable: true,
          class: 'col-sm-12 col-md-4',
          initialValue: this.selectedFilters.organization,
          onChanged: this.onOrganizationChanged,
        },
        {
          rules: '',
          name: 'name',
          label: this.$t('name'),
          component: 'BaseInput',
          placeholder: this.$t('name'),
          fieldtext: 'name',
          fieldvalue: 'id',
          class: 'col-sm-12 col-md-4',
          initialValue: this.selectedFilters.name,
          value: this.selectedFilters.name,
        },
      ];
    },
  },

  methods: {
    openNotificationModal(data) {
      this.selectedCompany = data;
      this.showNotificationFormModal = true;
    },
    openSSOModal(data) {
      this.selectedCompany = data;
      this.showSSOModal = true;
    },
    openAddNonWorkingDaysModal(data) {
      this.selectedCompany = data;
      this.showAddNonWorkingDaysModal = true;
    },
    openRemoveNonWorkingDaysModal(data) {
      this.selectedCompany = data;
      this.showRemoveNonWorkingDaysModal = true;
    },
    showSyncChatUsersConfirmation(data) {
      this.selectedCompany = data;
      this.showSyncChatModal = true;
    },
    async syncChatUsers(){
      await _syncChatUserAccounts({ companyId: this.selectedCompany.id })
        .then(() => {
          this.showSyncChatModal = false;
          this.ShowSuccessSaveToast();
        })
        .catch(error => this.ShowErrorToast(error.response.data.message));
    },
    async getOrganizations() {
      await _getOrganizations()
        .then((response) => {
          this.filters.organizations = response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onOrganizationChanged(value) {
      this.selectedFilters.organization = [];
      if (value) this.selectedFilters.organization = [value];
    },
    async getCompanies(pageIndex = null, pageSize = null) {
      this.pageSize = pageSize || this.pageSize;
      this.pageIndex = pageIndex || this.pageIndex;

      const payload = {
        OrganizationId:
          this.selectedFilters.organization.length > 0
            ? this.selectedFilters.organization[0].value
            : null,
        Name: this.selectedFilters.name || null,
        PageIndex: this.pageIndex,
        PageSize: this.pageSize,
      };

      await _getCompaniesList(payload)
        .then(({ data }) => {
          this.companies = data;
          this.noResultsFound = !this.companies.count;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>
